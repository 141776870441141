import React from 'react'
import './Home.scss'
import Tag from '../Tag'

const Home: React.FC = () => {
    return (
        <div className="flex justify-end">
            <div className="home w-2/4">
                <div className="home_intro flex justify-center">
                    <div className="flex flex-col justify-center items-center">
                        <div>
                            <figure><img src="https://media.licdn.com/dms/image/D4D03AQHUMOfbLn8tXw/profile-displayphoto-shrink_200_200/0/1693826334609?e=1703721600&v=beta&t=kH2pnPIXA4zVRdCOkynkeQ6ceV51ZwKqycf8pG2N6bc" alt="avatar"/></figure>
                            <h1 className="text-3xl font-bold underline text-clifford">Natasha Kaweski</h1>
                            <p>Hi, I'm Nat! I'm a Senior Design System working from Belo Horizonte-Brazil.</p>
                        </div>
                        <div className="flex gap-4 social">
                            <a href="https://www.linkedin.com/in/kaweski/" target="_blank">Linkedin</a>
                            <a href="https://www.instagram.com/apezincentoedois/" target="_blank">Apezin102</a>
                            <a href="https://www.instagram.com/hipihipiurra/" target="_blank">Hipihipiurra</a>
                            <a href="nat@kawe.ski" target="_blank">Mail</a>
                            <a href="https://dribbble.com/kaweski" target="_blank">Dribbble</a>
                            <a href="https://github.com/kaweski" target="_blank">GitHub</a>
                        </div>
                    </div>
                </div>

                <div className="home_section home_section-1 flex justify-center">
                    <div className="flex items-end justify-start">
                        <h1>
                            <div>New</div>
                            <div>Design System</div>
                        </h1>
                    </div>
                </div>

                <div className="go-down"> </div>

                <div className="home_section home_section-2">
                    <div className="boxes flex justify-stretch gap-4">
                        <div className="boxes_box box p-8">
                            <div className="title">
                                <h2>Research</h2>
                                <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Done</span>
                            </div>
                        </div>
                        <div className="boxes_box box p-8">
                            <div className="title">
                                <h2>Identidade Visual</h2>
                                <span className="inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10">Doing</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="go-down"> </div>

                <div className="home_section home_section-3 flex items-center">
                    <div className="boxes">
                        <div className="boxes_box box p-8">
                            <div className="title">
                                <h2>Foundations</h2>
                                <span className="tag tag--to-do">Backlog</span>
                            </div>
                        </div>
                    </div>
                    <div className="status-list">
                        <ul className="list">
                            <li>
                                <h4>Brand</h4>
                                {/* <span className="tag">To do</span> */}
                            </li>
                            <li>
                                <h4>Colors</h4>
                            </li>
                            <li>
                                <h4>Spacing</h4>
                            </li>
                            <li>
                                <h4>Typography</h4>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="go-down"> </div>

                <div className="home_section home_section-4">
                    <div className="boxes flex justify-center">
                        <div className="boxes_box box p-8">
                            <div className="title">
                                <h2>Icons</h2>
                                <span className="tag tag--to-do">Backlog</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="go-down"> </div>

                <div className="home_section home_section-5 flex items-center">
                    <div className="boxes flex justify-center">
                        <div className="boxes_box box p-8">
                            <div className="title">
                                <h2>Core Components</h2>
                                <span className="tag tag--to-do">Backlog</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="status-list">
                        <ul className="list">
                            <li>
                                <h4>Button</h4>
                                {/* <span className="tag">To do</span> */}
                            </li>
                            <li>
                                <h4>Heading/Text</h4>
                            </li>
                            <li>
                                <h4>Tabs</h4>
                            </li>
                            <li>
                                <h4>Header</h4>
                            </li>
                            <li>
                                <h4>Footer</h4>
                            </li>
                            <li>
                                <h4>Blog Components</h4>
                            </li>
                            <li>
                                <h4>...</h4>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="go-down"> </div>

                <div className="home_section home_section-6">
                    <div>How does it work?</div>
                </div>

            </div>
        </div>
    )
}

export default Home