import React, { createContext, useContext, useEffect, useState } from "react";
import parse from 'html-react-parser';
import { formatDate } from "../Utils";
import Box from "../Box/Box";
import { PostsContextData, postsContextValue, PostType } from './Types'
import { UpdatePostsList } from "./Request";
import { useLocation } from "react-router-dom";
import Button from "../Button/Button";
import './Post.scss'
import Tag from "../Tag";

const PostsContext = createContext<PostsContextData>(postsContextValue);

const Render: React.FC = () => {
	const { posts } = useContext(PostsContext)
	const { fetchPosts } = useContext(PostsContext)
	const location = useLocation()
	const [ currentPost, setCurrentPost ] = useState<PostType | null>()

	useEffect(() => {
		fetchPosts();
	}, [fetchPosts])

	useEffect(() => {
		const filteredPost = posts.filter(post => {
			console.log(post)
			return location.pathname.includes(post.slug)
		})
		setCurrentPost(filteredPost[0])
	}, [location, posts])

	return (
		<div className="posts">
			<Box>
				{currentPost
					?
						<article className="current-post">
							<div className="post-button">
								<Button url="/posts" color='gray'>Go back</Button>
							</div>
							<p className="post-date">{formatDate(currentPost.date)}</p>
							{/* <h2 className="post-title">{currentPost.summary}</h2> */}
							{currentPost.body ? <div className="post-body">{parse(currentPost.body)}</div> : ''}
							{/* {currentPost.tags?
								<div className="post-tags">
									{currentPost.tags?.map((tag, key) => <Tag key={key} text={tag}/>)}
								</div>
							: ''} */}
						</article>
					: posts.map((post, key) => {
						return (
							<article className="post" key={key}>
								<p className="post-date">{formatDate(post.date)}</p>
								<h2 className="post-title">{post.summary}</h2>
								{/* {post.body ? <div className="post-body">{parse(post.body)}</div> : ''} */}
								<div className="post-button">
									<Button url={post.slug} color='gray'>Read more</Button>
								</div>
							</article>
						)
					})
				}
			</Box>
		</div>
	)
}

function Posts() {
	const postsContextValue = UpdatePostsList();
	return (
		<PostsContext.Provider value={postsContextValue}>
			<Render />
		</PostsContext.Provider>
	);
}

export default Posts;