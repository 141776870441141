import React from 'react'
import Box from '../Box/Box'
import './About.scss'

const About: React.FC = () => {
    return (
        <div className="about">
            <Box>
                <p>Hello there! 👋 My name is Natasha, but you can call me Nat.</p>
                <p>I'm an experienced professional with a background in Computer Science and Design. Throughout my career, I've gained valuable expertise in various roles.</p>
                <p>For about six years, I immersed myself in the world of web development and design as a Senior Front-end Developer. In September 2020, I made an exciting transition into the realm of Design System Ops, where I now specialize. This shift allowed me to leverage my strong development background to approach design challenges from a unique perspective.</p>
                <p>My journey has equipped me with a deep understanding of how code and design intertwine to create seamless user experiences. I excel in building agnostic design systems that empower teams to deliver consistent, efficient, and collaborative solutions across various platforms and technologies.</p>
                <p>With a diverse skill set encompassing Figma, React, TypeScript, Redux, HTML, CSS, and WordPress, I am well-versed in crafting visually stunning and functional designs. I am also proficient in Adobe Photoshop and Illustrator, enabling me to create captivating graphics and illustrations.</p>
                <p>Collaboration is at the heart of my work. I enjoy working closely with teams to develop innovative solutions that not only meet business objectives but also exceed user expectations. As a natural communicator, I thrive in environments that foster creativity and collaboration.</p>
            </Box>
        </div>
    )
}

export default About