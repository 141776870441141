import React from 'react'
import './Box.scss'

const Box: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    // const BoxStyles: React.CSSProperties = {
    //     padding: '64px',
    //     borderRadius: '16px',
    //     border: '4px solid #111',
    //     backgroundColor: '#000',
    //     color: '#f5f5f5',
    //     width: '100%',
    //     height: '100%',
    //     overflow: 'scroll'
    // }

    // return (
    //     <div style={BoxStyles}>
    //         {children}
    //     </div>
    // )

    return (
        <div className="box">
            {children}
        </div>
    )
}

export default Box